<template>
    <div id="index">
        <a-layout class="layout-wrapper">
            <!-- 侧边栏 -->
            <a-layout-sider
                class="sider-wrapper"
                v-model="collapsed"
                collapsible
            >
                <!-- logo -->
                <div class="logo-and-title">
                    <img
                        v-if="!collapsed"
                        src="@/assets/images/logo-and-name.png"
                        alt=""
                    />
                    <img v-else src="@/assets/images/logo-logo.png" alt="" />
                </div>

                <a-menu
                    v-model="selectKeys"
                    :open-keys.sync="openKeys"
                    mode="inline"
                    theme="dark"
                    :inline-collapsed="collapsed"
                >
                    <template v-for="item in menuList">
                        <a-sub-menu :key="item.key">
                            <div slot="title">
                                <a-icon :type="item.icon" />
                                <span>{{ item.title }}</span>
                            </div>

                            <template v-for="menuItem in item.children">
                                <a-menu-item
                                    :key="menuItem.key"
                                    @click="
                                        handleMenu(
                                            menuItem.pageName,
                                            menuItem.key
                                        )
                                    "
                                >
                                    <a-icon :type="menuItem.icon" />
                                    <span>{{ menuItem.title }}</span>
                                </a-menu-item>
                            </template>
                        </a-sub-menu>
                    </template>
                </a-menu>
            </a-layout-sider>

            <a-layout class="content-box-wrapper">
                <!-- 顶部 -->
                <a-layout-header class="header-wrapper">
                    <!-- 搜索 -->
                    <a-input-search
                        class="header-search"
                        size="large"
                        placeholder="搜索"
                        @search="onSearch"
                    />

                    <!-- 提示 -->
                    <a-icon class="header-tips" type="bell" />

                    <!-- 用户信息 -->
                    <a-dropdown>
                        <div class="user-info">
                            <a-avatar
                                :src="userInfo.avatar"
                                size="large"
                                icon="user"
                            />

                            <span class="username">{{
                                userInfo.username
                            }}</span>
                        </div>

                        <a-menu slot="overlay">
                            <a-menu-item @click="handleLogout">
                                <span>退出登录</span>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </a-layout-header>

                <!-- 内容 -->
                <a-layout-content class="content-wrapper">
                    <keep-alive :include="include">
                        <router-view></router-view>
                    </keep-alive>
                </a-layout-content>
            </a-layout>
        </a-layout>
    </div>
</template>

<script>
import { userLogout } from "network/login";

import { mapGetters } from "vuex";

export default {
    name: "Index", // 首页
    data() {
        return {
            include: [],

            collapsed: false,

            selectKeys: [],
            openKeys: [],
        };
    },
    computed: {
        ...mapGetters(["userInfo", "menuList"]),
    },
    watch: {
        '$route'(to) {
            if (to.name === 'index') {
                this.openMenu()
            }
        }
    },
    mounted() {
        this.openMenu()
    },
    methods: {
        // ---------------事件处理函数---------------
        // 进来时展开对应选项
        openMenu() {
            if (this.$route.name === "index") {
                // 是index页面时，初始化选中页面
                const menuItem = this.menuList[0].children[0];
                this.selectKeys = [menuItem.key];
                this.openKeys = [this.menuList[0].key];

                let pageName = menuItem.pageName;
                this.include = [menuItem.key];

                this.$router.replace({ name: pageName });
            } else {
                this.menuList.forEach((menu) => {
                    menu.children.length > 0 &&
                        menu.children.forEach((item) => {
                            if (this.$route.name === item.pageName) {
                                this.openKeys = [menu.key];
                                this.selectKeys = [item.key];
                                this.include = [item.key];
                            }
                        });
                });
            }
        },

        // 搜索
        onSearch(value) {
            console.log(value);
        },

        // 点击菜单
        handleMenu(name, key) {
            this.include = [key];

            this.$router.replace({ name });
        },

        // 退出登录
        handleLogout() {
            this._userLogout();
        },

        // ------------------网络请求------------------
        // 退出登录
        _userLogout() {
            userLogout()
                .then((res) => {
                    this.include = [];
                    this.$router.replace({ name: "login" });
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="less" scoped>
#index {
    width: 100vw;
    height: 100vh;
    position: relative;

    .layout-wrapper {
        width: 100vw;
        height: 100vh;
        position: relative;
        display: flex;

        .sider-wrapper {
            height: 100%;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
                color: transparent;
            }

            .logo-and-title {
                height: 64px;
                // background: rgba(255, 255, 255, 0.2);
                // padding: 10px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .content-box-wrapper {
            flex: 1;
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .header-wrapper {
                width: 100%;
                height: 64px;
                background: #fff;
                display: inline-flex;
                justify-content: flex-end;
                align-items: center;
                box-shadow: 0 2px 2px rgba(245, 245, 245, 0.5);
                position: relative;
                z-index: 999;

                .header-search {
                    width: 200px;
                }

                .header-tips {
                    margin: 0 30px;
                    font-size: 24px;
                }

                .user-info {
                    display: flex;
                    align-items: center;

                    .username {
                        margin-left: 10px;
                        font-size: 18px;
                    }
                }
            }

            .content-wrapper {
                flex: 1;
                overflow: auto;
                width: 100%;
                position: relative;
            }
        }
    }
}
</style>